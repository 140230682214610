import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Avatar, Button, Icon, Menu } from "antd";
import { LOG_OUT } from "../../../../lib/graphql/mutations";
import { LogOut as LogOutData } from "../../../../lib/graphql/mutations/LogOut/__generated__/LogOut";
import {
  displaySuccessNotification,
  displayErrorMessage
} from "../../../../lib/utils";
import { Viewer } from "../../../../lib/types";

interface Props {
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
}

const { Item, SubMenu } = Menu;

export const MenuItems = ({ viewer, setViewer }: Props) => {
  const [logOut] = useMutation<LogOutData>(LOG_OUT, {
    onCompleted: data => {
      if (data && data.logOut) {
        setViewer(data.logOut);
        displaySuccessNotification(
          "You've successfully logged out!"
        );
      }
    },
    onError: () => {
      displayErrorMessage(
        "Sorry! We weren't able to log you out. Please try again later!"
      );
    },
    refetchQueries: ["User", "Listing"] // to be added later on
  });

  const handleLogOut = () => {
    logOut();
  };

  const avatarElement =
    viewer.id && viewer.avatar ? (
      <Avatar src={viewer.avatar} />
    ) : (
      <span>
        <Icon type="setting" />
        User
      </span>
    );

  const subMenuLoginElement = viewer.id ? (
    <SubMenu title={avatarElement}>
      <Item key={`/user/${viewer.id}`}>
        <Link to={`/user/${viewer.id}`}>
          <Icon type="user" />
          Profile
        </Link>
      </Item>
      <Item key="logout">
        <div onClick={handleLogOut}>
          <Icon type="logout" />
          Log out
        </div>
      </Item>
    </SubMenu>
  ) : (
    <Item key="/login">
      <Link to="/login">
        <Button type="primary">Sign In</Button>
      </Link>
    </Item>
  );

  return (
    <Fragment>
      <Menu
        mode="horizontal"
        selectable={false}
        className="menu"
      >
        <Item key="/host">
          <Link to="/host">
            <Icon type="home" />
            Host
          </Link>
        </Item>
        {subMenuLoginElement}
      </Menu>
    </Fragment>
  );
};
