import React, {
  useState,
  useLayoutEffect,
  FormEvent
} from "react";
import { Link, Redirect } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  Radio,
  Typography,
  Upload,
  message
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { UploadChangeParam } from "antd/lib/upload";
import { HOST_LISTING } from "../../lib/graphql/mutations";
import {
  HostListing as HostListingData,
  HostListingVariables
} from "../../lib/graphql/mutations/HostListing/__generated__/HostListing";
import {
  displaySuccessNotification,
  displayErrorMessage
} from "../../lib/utils";
import { Viewer } from "../../lib/types";

interface Props {
  viewer: Viewer;
}

type HostProps = Props & FormComponentProps;

const { Content } = Layout;
const { Text, Title } = Typography;
const { Item } = Form;

const iconColor = "#1890ff";

const Host = ({ viewer, form }: HostProps) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [imageBase64Value, setImageBase64Value] = useState<
    string | null
  >(null);

  const [hostListing, { loading, data }] = useMutation<
    HostListingData,
    HostListingVariables
  >(HOST_LISTING, {
    onCompleted: () => {
      displaySuccessNotification(
        "You've successfully created your listing!"
      );
    },
    onError: () => {
      displayErrorMessage(
        "Sorry! We weren't able to create your listing. Please try again later!"
      );
    }
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageUpload = (info: UploadChangeParam) => {
    const { file } = info;

    if (file.status === "uploading") {
      setImageLoading(true);
      return;
    }

    if (file.status === "done" && file.originFileObj) {
      getBase64Value(
        file.originFileObj,
        imageBase64Value => {
          setImageBase64Value(imageBase64Value);
          setImageLoading(false);
        }
      );
    }
  };

  const handleHostListing = async (evt: FormEvent) => {
    evt.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        displayErrorMessage(
          "Please complete all required form fields!"
        );
        return;
      }

      const fullAddress = `${values.address}, ${values.city}, ${values.state}, ${values.postalCode}`;

      const input = {
        ...values,
        address: fullAddress,
        image: imageBase64Value,
        price: values.price * 100
      };
      delete input.city;
      delete input.state;
      delete input.postalCode;

      hostListing({
        variables: {
          input
        }
      });
    });
  };

  if (!viewer.id || !viewer.hasWallet) {
    return (
      <Content className="host-content">
        <div className="host__form-header">
          <Title level={4} className="host__form-title">
            You'll have to be signed in and connected with
            Stripe to host a listing!
          </Title>
          <Text style={{ fontSize: "15px" }}>
            We only allows users who've signed in to our
            application and have connected with Stripe to
            host new listings. You can sign in at the{" "}
            <Link to="/login"> /login</Link> page and
            connect with Stripe shortly after.
          </Text>
        </div>
      </Content>
    );
  }

  if (loading) {
    return (
      <Content className="host-content">
        <div className="host__form-header">
          <Title level={3} className="host__form-title">
            Please Wait!
          </Title>
          <Text>We are creating your listing now!</Text>
        </div>
      </Content>
    );
  }

  if (data && data.hostListing) {
    return (
      <Redirect to={`/listing/${data.hostListing.id}`} />
    );
  }

  return (
    <Content className="host-content">
      <Form layout="vertical" onSubmit={handleHostListing}>
        <div className="host__form-header">
          <Title level={3} className="host__form-title">
            Hi! Let's get started listing your place.
          </Title>
          <Text type="secondary">
            In this form, we'll collect some basic and
            additional information about your listing.
          </Text>
        </div>

        <Item label="Home Type">
          {form.getFieldDecorator("type", {
            rules: [
              {
                required: true,
                message: "Please select a home type!"
              }
            ]
          })(
            <Radio.Group>
              <Radio.Button value="apartment">
                <Icon
                  type="bank"
                  style={{ color: iconColor }}
                />{" "}
                <span>Apartment</span>
              </Radio.Button>
              <Radio.Button value="house">
                <Icon
                  type="home"
                  style={{ color: iconColor }}
                />{" "}
                <span>House</span>
              </Radio.Button>
            </Radio.Group>
          )}
        </Item>

        <Item label="Max # of Guests">
          {form.getFieldDecorator("numOfGuests", {
            rules: [
              {
                required: true,
                message:
                  "Please enter the max number of guests!"
              }
            ]
          })(<InputNumber min={1} placeholder="4" />)}
        </Item>

        <Item
          label="Title"
          extra="Max character count of 45"
        >
          {form.getFieldDecorator("title", {
            rules: [
              {
                required: true,
                message:
                  "Please enter a title for your listing!"
              }
            ]
          })(
            <Input
              maxLength={45}
              placeholder="The iconic and luxurious Bel-Air mansion"
            />
          )}
        </Item>

        <Item
          label="Description of listing"
          extra="Max character count of 400"
        >
          {form.getFieldDecorator("description", {
            rules: [
              {
                required: true,
                message:
                  "Please enter a description for your listing!"
              }
            ]
          })(
            <Input.TextArea
              rows={3}
              maxLength={400}
              placeholder="Modern, clean, and iconic home of the Fresh Prince. Situated in the heart of Bel-Air, Los Angeles."
            />
          )}
        </Item>

        <Item label="Address">
          {form.getFieldDecorator("address", {
            rules: [
              {
                required: true,
                message:
                  "Please enter an address for your listing!"
              }
            ]
          })(
            <Input placeholder="251 North Bristol Avenue" />
          )}
        </Item>

        <Item label="City/Town">
          {form.getFieldDecorator("city", {
            rules: [
              {
                required: true,
                message:
                  "Please enter a city (or region) for your listing!"
              }
            ]
          })(<Input placeholder="Los Angeles" />)}
        </Item>

        <Item label="State/Province">
          {form.getFieldDecorator("state", {
            rules: [
              {
                required: true,
                message:
                  "Please enter a state for your listing!"
              }
            ]
          })(<Input placeholder="California" />)}
        </Item>

        <Item label="Zip/Postal Code">
          {form.getFieldDecorator("postalCode", {
            rules: [
              {
                required: true,
                message:
                  "Please enter a zip code for your listing!"
              }
            ]
          })(
            <Input placeholder="Please enter a zip code for your listing!" />
          )}
        </Item>

        <Item
          label="Image"
          extra="Images have to be under 1MB in size and of type JPG or PNG"
        >
          {form.getFieldDecorator("image", {
            rules: [
              {
                required: true,
                message: "Listing image is required!"
              }
            ]
          })(
            <div className="host__form-image-upload">
              <Upload
                name="image"
                listType="picture-card"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeImageUpload}
                onChange={handleImageUpload}
              >
                {imageBase64Value ? (
                  <img
                    src={imageBase64Value}
                    alt="listing"
                  />
                ) : (
                  <div>
                    <Icon
                      type={
                        imageLoading ? "loading" : "plus"
                      }
                    />
                    <div className="ant-upload-text">
                      Upload
                    </div>
                  </div>
                )}
              </Upload>
            </div>
          )}
        </Item>

        <Item label="Price" extra="All prices in $USD/day">
          {form.getFieldDecorator("price", {
            rules: [
              {
                required: true,
                message:
                  "Please enter a price for your listing!"
              }
            ]
          })(
            <InputNumber type="number" placeholder="120" />
          )}
        </Item>

        <Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Text
            mark
            style={{ display: "block", padding: "10px 0" }}
          >
            Though you're able to fill this form - upon
            submission, we autogenerate the creation of a
            random listing since we don't vet submissions!
          </Text>
        </Item>
      </Form>
    </Content>
  );
};

const beforeImageUpload = (file: File) => {
  const fileIsValidImage =
    file.type === "image/jpeg" || file.type === "image/png";
  const fileIsValidSize = file.size / 1024 / 1024 < 1;

  if (!fileIsValidImage) {
    message.error(
      `You're only able to upload valid JPG or PNG files!`
    );
  }

  if (!fileIsValidSize) {
    message.error(
      `You're only able to upload valid images files of under 1MB in size!`
    );
  }

  return fileIsValidImage && fileIsValidSize;
};

const getBase64Value = (
  img: File | Blob,
  callback: (imageBase64Value: string) => void
) => {
  const reader = new FileReader();
  if (img) reader.readAsDataURL(img);
  reader.addEventListener("load", () =>
    callback(reader.result as string)
  );
};

export const WrappedHost = Form.create<HostProps>({
  name: "host_form"
})(Host);
