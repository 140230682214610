import React, { MouseEvent, ReactChild } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Button } from "antd";

interface Props {
  to: string;
  children: ReactChild;
  size?: "default" | "large" | "small";
  type?: "default" | "primary" | "ghost" | "dashed" | "danger";
  className?: string;
}

type ButtonLinkProps = Props & RouteComponentProps;

export const ButtonLink = withRouter(
  ({
    to,
    children,
    size = "default",
    type = "default",
    history,
    className
  }: ButtonLinkProps) => {
    const navigateToRoute = (evt: MouseEvent) => {
      evt.preventDefault();
      history.push(to);
    };

    return (
      <Button
        href={to}
        size={size}
        type={type}
        onClick={navigateToRoute}
        className={className}
      >
        {children}
      </Button>
    );
  }
);
