import React, { Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Icon,
  Tag,
  Tooltip,
  Typography
} from "antd";
import {
  formatListingPrice,
  displaySuccessNotification,
  displayErrorMessage
} from "../../../../lib/utils";
import { DISCONNECT_STRIPE } from "../../../../lib/graphql/mutations/";
import { DisconnectStripe as DisconnectStripeData } from "../../../../lib/graphql/mutations/DisconnectStripe/__generated__/DisconnectStripe";
import { User as UserData } from "../../../../lib/graphql/queries/User/__generated__/User";
import { Viewer } from "../../../../lib/types";

type SetViewerCallback = (viewer: Viewer) => Viewer;

interface Props {
  viewer: Viewer;
  user: UserData["user"];
  viewerIsUser: boolean;
  setViewer: (callback: SetViewerCallback) => void;
  handleRefetch: () => void;
}

const StripeAuthUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_S_CLIENT_ID}&scope=read_write`;
const { Paragraph, Text, Title } = Typography;
const iconColor = "#1890ff";

export const UserProfile = ({
  viewer,
  user,
  viewerIsUser,
  setViewer,
  handleRefetch
}: Props) => {
  const [disconnectStripe, { loading }] = useMutation<
    DisconnectStripeData
  >(DISCONNECT_STRIPE, {
    onCompleted: data => {
      if (data && data.disconnectStripe) {
        setViewer((viewer: Viewer) => {
          return {
            ...viewer,
            hasWallet: data.disconnectStripe.hasWallet
          };
        });

        displaySuccessNotification(
          "You've successfully disconnected from Stripe!",
          "You'll have to reconnect with Stripe to continue to create listings."
        );

        handleRefetch();
      }
    },
    onError: () => {
      displayErrorMessage(
        "Sorry! We weren't able to disconnect you from Stripe. Please try again later!"
      );
    }
  });

  const redirectToStripe = () => {
    window.location.href = StripeAuthUrl;
  };

  const handleDisconnectStripe = () => {
    if (!viewer.token) {
      return displayErrorMessage(
        "Sorry! You'll have to be logged in to disconnect from Stripe."
      );
    }

    disconnectStripe();
  };

  const additionalDetails = user.hasWallet ? (
    <Fragment>
      <Paragraph>
        <Tag color="green">Stripe Registered</Tag>
      </Paragraph>
      <Paragraph>
        Income Earned:{" "}
        <Text strong>
          {user.income
            ? formatListingPrice(user.income)
            : `$0`}
        </Text>
      </Paragraph>
      <Button
        type="primary"
        loading={loading}
        onClick={handleDisconnectStripe}
        className="user-profile__details-cta"
      >
        Disconnect Stripe
      </Button>
      <Paragraph type="secondary">
        By disconnecting, you won't be able to receive{" "}
        <Text strong>any further payments</Text>. This will
        prevent users from booking listings that you might
        have already created.
      </Paragraph>
    </Fragment>
  ) : (
    <Fragment>
      <Paragraph>
        Interested in becoming a TinyHouse host? Register
        with your Stripe account!
      </Paragraph>
      <Button
        type="primary"
        onClick={redirectToStripe}
        className="user-profile__details-cta"
      >
        Connect with Stripe!
      </Button>
      <Paragraph type="secondary">
        When redirected to the Stripe account activation
        form, click the{" "}
        <Text code>Skip this account form</Text> link
        presented at the top to connect with Stripe in
        development mode.
      </Paragraph>
    </Fragment>
  );

  const additionalDetailsSection = viewerIsUser ? (
    <Fragment>
      <Divider />
      <div className="user-profile__details">
        <Title level={4}>
          Additional Details{" "}
          <Tooltip title="Only seen when logged in as user">
            <Icon
              type="question-circle"
              style={{ color: iconColor }}
            />
          </Tooltip>{" "}
        </Title>
        {additionalDetails}
      </div>
    </Fragment>
  ) : null;

  return (
    <Fragment>
      <div className="user-profile">
        <Card className="user-profile__card">
          <div className="user-profile__avatar">
            <Avatar size={100} src={user.avatar} />
          </div>
          <Divider />
          <div className="user-profile__details">
            <Title level={4}>Details</Title>
            <Paragraph>
              Name: <Text strong>{user.name}</Text>
            </Paragraph>
            <Paragraph>
              Contact: <Text strong>{user.contact}</Text>
            </Paragraph>
          </div>
          {additionalDetailsSection}
        </Card>
      </div>
    </Fragment>
  );
};
