import React from "react";
import {
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import {
  CardElement,
  injectStripe
} from "react-stripe-elements";
import {
  Button,
  Divider,
  Icon,
  Modal,
  Tooltip,
  Typography
} from "antd";
import moment, { Moment } from "moment";
import { CREATE_BOOKING } from "../../../../lib/graphql/mutations";
import {
  CreateBooking as CreateBookingData,
  CreateBookingVariables
} from "../../../../lib/graphql/mutations/CreateBooking/__generated__/CreateBooking";
import {
  formatListingPrice,
  displaySuccessNotification,
  displayErrorMessage
} from "../../../../lib/utils";
import { Viewer } from "../../../../lib/types";
import { StripeProps } from "./types";

interface Props {
  viewer: Viewer;
  price: number;
  modalVisible: boolean;
  checkInDate: Moment;
  checkOutDate: Moment;
  setModalVisible: (modalVisible: boolean) => void;
  clearBookingData: () => void;
  handleRefetch: () => Promise<void>;
}

interface MatchParams {
  id: string;
}

type ListingBookingModalProps = Props &
  RouteComponentProps<MatchParams> &
  StripeProps;

const { Paragraph, Text, Title } = Typography;
const iconColor = "#1890ff";

const ListingCreateBookingModal = ({
  viewer,
  price,
  modalVisible,
  checkInDate,
  checkOutDate,
  setModalVisible,
  match,
  stripe,
  clearBookingData,
  handleRefetch
}: ListingBookingModalProps) => {
  const [createBooking, { loading }] = useMutation<
    CreateBookingData,
    CreateBookingVariables
  >(CREATE_BOOKING, {
    onCompleted: () => {
      clearBookingData();
      displaySuccessNotification(
        "You've successfully booked the listing!",
        "Booking history can always be found in your User page."
      );
      handleRefetch();
    },
    onError: () => {
      displayErrorMessage(
        "Sorry! We weren't able to successfully book the listing. Please try again later!"
      );
    }
  });

  const handleCreateBooking = async () => {
    const genericMessageError =
      "Sorry! We weren't able to book the listing. Please try again later!";

    if (!viewer.token) {
      return displayErrorMessage(
        "Sorry! You'll have to be logged in to create a booking"
      );
    }

    if (!stripe) {
      return displayErrorMessage(
        "Sorry! We weren't able to connect with Stripe"
      );
    }

    let {
      token: stripeToken,
      error
    } = await stripe.createToken();

    if (stripeToken) {
      createBooking({
        variables: {
          input: {
            id: match.params.id,
            source: stripeToken.id,
            checkIn: moment(checkInDate).format(
              "YYYY-MM-DD"
            ),
            checkOut: moment(checkOutDate).format(
              "YYYY-MM-DD"
            )
          }
        }
      });
    } else {
      displayErrorMessage(
        error && error.message
          ? error.message
          : genericMessageError
      );
    }
  };

  const daysBooked =
    checkOutDate.diff(checkInDate, "days") + 1;
  const listingPrice = (price / 100) * daysBooked;
  const tinyHouseFee = (5 / 100) * listingPrice;
  const totalPrice = listingPrice + tinyHouseFee;

  return (
    <Modal
      visible={modalVisible}
      centered
      footer={null}
      onCancel={() => setModalVisible(false)}
    >
      <div className="listing-booking-modal">
        <div className="listing-booking-modal__intro">
          <Title className="listing-booking-modal__intro-title">
            <Icon type="key" />
          </Title>
          <Title
            level={3}
            className="listing-booking-modal__intro-title"
          >
            Book your trip
          </Title>
          <Paragraph>
            Enter your payment information to book the
            listing from the dates between{" "}
            <Text mark strong>
              {moment(checkInDate).format("MMMM Do YYYY")}
            </Text>{" "}
            to{" "}
            <Text mark strong>
              {moment(checkOutDate).format("MMMM Do YYYY")}
            </Text>
            , inclusive.
          </Paragraph>
        </div>

        <Divider />

        <div className="listing-booking-modal__charge-summary">
          <Paragraph>
            {formatListingPrice(price, false)} x{" "}
            {daysBooked} days ={" "}
            <Text strong>${listingPrice.toFixed(2)}</Text>
          </Paragraph>
          <Paragraph>
            TinyHouse fee{" "}
            <Tooltip title="5% fee of total amount">
              <Icon
                type="question-circle"
                style={{ color: iconColor }}
              />
            </Tooltip>{" "}
            = <Text strong>${tinyHouseFee.toFixed(2)}</Text>
          </Paragraph>
          <Paragraph className="listing-booking-modal__charge-summary-total">
            Total ={" "}
            <Text mark>${totalPrice.toFixed(2)}</Text>{" "}
            <Text
              type="secondary"
              className="listing-booking-modal__charge-summary-stripe"
            >
              + additional{" "}
              <a
                href="https://stripe.com/en-ca/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe fees
              </a>
            </Text>
          </Paragraph>
        </div>

        <Divider />

        <div className="listing-booking-modal__stripe-card-section">
          <CardElement
            hidePostalCode={true}
            className="listing-booking-modal__stripe-card"
          />
          <Button
            size="large"
            type="primary"
            className="listing-booking-modal__cta"
            loading={loading}
            onClick={handleCreateBooking}
          >
            Book
          </Button>
        </div>

        <Paragraph type="secondary">
          Test using the credit card number: 4242 4242 4242
          4242, a future expiry date, and any 3 digits for
          the CVC code.
        </Paragraph>
      </div>
    </Modal>
  );
};

export const WrappedListingCreateBookingModal = injectStripe(
  withRouter(ListingCreateBookingModal)
);
