import React, { Fragment } from "react";
import { Skeleton } from "antd";

export const PageSkeleton = () => {
  const numberOfSkeletonParagraphs = 3;

  return (
    <Fragment>
      {[...Array(numberOfSkeletonParagraphs)].map(
        (_e: undefined, idx: number) => (
          <Skeleton
            active
            paragraph={{ rows: 4 }}
            key={idx}
            className="page-skeleton__paragraph"
          />
        )
      )}
    </Fragment>
  );
};
