import React, {
  useState,
  useEffect,
  ChangeEvent
} from "react";
import {
  Link,
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import { Input, Layout } from "antd";
import { displayErrorMessage } from "../../lib/utils";
import { Viewer } from "../../lib/types";
import { MenuItems } from "./components";

// Image Assets
import logo from "./assets/logo.png";

interface Props {
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
}

type AppHeaderProps = Props & RouteComponentProps;

const { Header } = Layout;

export const AppHeader = withRouter(
  ({
    viewer,
    setViewer,
    history,
    location
  }: AppHeaderProps) => {
    const [search, setSearch] = useState("");

    useEffect(() => {
      const { pathname } = location;
      const pathnameSubStrings = pathname.split("/");

      if (!pathname.includes("/listings")) {
        setSearch("");
        return;
      }

      if (
        pathname.includes("/listings") &&
        pathnameSubStrings.length === 3
      ) {
        setSearch(pathnameSubStrings[2]);
        return;
      }
    }, [location]);

    const submitSearch = (value: string) => {
      const trimmedSearch = value.trim();

      if (trimmedSearch) {
        history.push(`/listings/${trimmedSearch}`);
      } else {
        displayErrorMessage("Please enter a valid search!");
      }
    };

    return (
      <Header className="app-header">
        <div className="app-header__logo-search-section">
          <div className="app-header__logo">
            <Link to="/">
              <img src={logo} alt="App logo" />
            </Link>
          </div>
          <div className="app-header__search-input">
            <Input.Search
              placeholder="Search 'San Fransisco'"
              value={search}
              onChange={(
                evt: ChangeEvent<HTMLInputElement>
              ) => setSearch(evt.target.value)}
              onSearch={submitSearch}
              enterButton
            />
          </div>
        </div>
        <div className="app-header__menu-section">
          <MenuItems
            viewer={viewer}
            setViewer={setViewer}
          />
        </div>
      </Header>
    );
  }
);
