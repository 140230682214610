import React from "react";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Tooltip,
  Typography,
  message
} from "antd";
import moment, { Moment } from "moment";
import { Listing as ListingData } from "../../../../lib/graphql/queries/Listing/__generated__/Listing";
import { formatListingPrice } from "../../../../lib/utils";
import { Viewer } from "../../../../lib/types";
import { BookingsIndex } from "./types";

interface Props {
  viewer: Viewer;
  host: ListingData["listing"]["host"];
  price: number;
  bookingsIndex: string;
  checkInDate?: Moment;
  checkOutDate?: Moment;
  setCheckInDate: (checkInDate: Moment) => void;
  setCheckOutDate: (checkOutDate?: Moment) => void;
  setModalVisible: (modalVisible: boolean) => void;
}

const { Paragraph, Text, Title } = Typography;

export const ListingCreateBooking = ({
  viewer,
  host,
  price,
  bookingsIndex,
  checkInDate,
  checkOutDate,
  setCheckInDate,
  setCheckOutDate,
  setModalVisible
}: Props) => {
  const bookingsIndexJSON: BookingsIndex = JSON.parse(bookingsIndex);

  const disabledCheckInDate = (current?: Moment) => {
    if (current) {
      const dateIsBeforeEndOfDay = moment(current).isBefore(
        moment().endOf("day")
      );
      const dateIsMoreThanTwoWeeksAhead = moment(current).isAfter(
        moment()
          .endOf("day")
          .add(2, "week")
      );

      return (
        dateIsBeforeEndOfDay ||
        dateIsMoreThanTwoWeeksAhead ||
        dateIsBooked(current)
      );
    } else {
      return false;
    }
  };

  const dateIsBooked = (current: Moment) => {
    const year = moment(current).year();
    const month = moment(current).month();
    const day = moment(current).date();

    if (bookingsIndexJSON[year] && bookingsIndexJSON[year][month]) {
      return Boolean(bookingsIndexJSON[year][month][day]);
    } else {
      return false;
    }
  };

  const verifyAndSetCheckOutDate = (checkOutDate: Moment) => {
    if (moment(checkOutDate).isBefore(checkInDate, "days")) {
      return message.error(
        `You can't book date of check out to be prior to check in! Please try again!`
      );
    }

    let dateCursor = checkInDate;

    while (moment(dateCursor).isBefore(checkOutDate, "days")) {
      dateCursor = moment(dateCursor).add(1, "days");

      const yearOfDateCursor = moment(dateCursor).year();
      const monthOfDateCursor = moment(dateCursor).month();
      const dayOfDateCursor = moment(dateCursor).date();

      if (
        bookingsIndexJSON[yearOfDateCursor] &&
        bookingsIndexJSON[yearOfDateCursor][monthOfDateCursor] &&
        bookingsIndexJSON[yearOfDateCursor][monthOfDateCursor][dayOfDateCursor]
      ) {
        return message.error(
          `You can't book a period of time that overlaps existing bookings! Please try again!`
        );
      }
    }

    setCheckOutDate(checkOutDate);
  };

  const viewerIsHost = viewer.id === host.id;
  const checkInInputDisabled = !viewer.id || viewerIsHost || !host.hasWallet;
  const checkOutInputDisabled = checkInInputDisabled || !checkInDate;
  const buttonDisabled = checkOutInputDisabled || !checkInDate || !checkOutDate;

  let buttonMessage = "You won't be charged yet";
  if (!viewer.id) {
    buttonMessage = "You have to be signed in to book a listing!";
  } else if (viewerIsHost) {
    buttonMessage = "You can't book your own listing!";
  } else if (!host.hasWallet) {
    buttonMessage =
      "The host has disconnected from Stripe and thus won't be able to receive payments.";
  }

  return (
    <div className="listing-booking">
      <Card className="listing-booking__card">
        <div>
          <Paragraph>
            <Title level={2} className="listing-booking__card-title">
              {formatListingPrice(price)}
              <span>/day</span>
            </Title>
          </Paragraph>
          <Divider />
          <div className="listing-booking__card-date-picker">
            <Paragraph strong>Check In</Paragraph>
            <DatePicker
              value={checkInDate}
              disabled={checkInInputDisabled}
              format={"YYYY/MM/DD"}
              showToday={false}
              disabledDate={disabledCheckInDate}
              onChange={checkInDate =>
                checkInDate && setCheckInDate(checkInDate)
              }
              onOpenChange={() => setCheckOutDate(undefined)}
              renderExtraFooter={() => {
                return (
                  <div>
                    <Text type="secondary" className="ant-calendar-footer-text">
                      You can only book a listing for a maximum period of two
                      weeks.
                    </Text>
                  </div>
                );
              }}
            />
          </div>
          <div className="listing-booking__card-date-picker">
            <Paragraph strong>Check Out</Paragraph>
            <DatePicker
              value={checkOutDate}
              disabled={checkOutInputDisabled}
              format={"YYYY/MM/DD"}
              showToday={false}
              disabledDate={disabledCheckInDate}
              onChange={checkOutDate =>
                checkOutDate && verifyAndSetCheckOutDate(checkOutDate)
              }
              dateRender={current => {
                if (moment(current).isSame(checkInDate, "day")) {
                  return (
                    <Tooltip title="Check in date">
                      <div className="ant-calendar-date ant-calendar-date__check-in">
                        {current.date()}
                      </div>
                    </Tooltip>
                  );
                } else {
                  return (
                    <div className="ant-calendar-date">{current.date()}</div>
                  );
                }
              }}
              renderExtraFooter={() => {
                return (
                  <div>
                    <Text type="secondary" className="ant-calendar-footer-text">
                      Check out cannot be before check in and must be within a
                      suitable range of dates.
                    </Text>
                  </div>
                );
              }}
            />
          </div>
          <Divider />
        </div>
        <Button
          disabled={buttonDisabled}
          size="large"
          type="primary"
          className="listing-booking__card-cta"
          onClick={() => setModalVisible(true)}
        >
          Request to book!
        </Button>
        <Text type="secondary" mark>
          {buttonMessage}
        </Text>
      </Card>
    </div>
  );
};
