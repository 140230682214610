import React, { useLayoutEffect } from "react";
import {
  Link,
  RouteComponentProps
} from "react-router-dom";
import { ApolloError } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Col, Layout, Row, Typography } from "antd";
import { ButtonLink } from "../../lib/components";
import { LISTINGS } from "../../lib/graphql/queries";
import {
  Listings as ListingsData,
  ListingsVariables
} from "../../lib/graphql/queries/Listings/__generated__/Listings";
import { ListingsFilter } from "../../lib/graphql/globalTypes";
import { displayErrorMessage } from "../../lib/utils";
import {
  HomeHero,
  HomeListings,
  HomeListingsSkeleton
} from "./components";

// Image Assets
import sanFransiscoImage from "./assets/san-fransisco.jpg";
import cancunImage from "./assets/cancun.jpg";

const { Content } = Layout;
const { Paragraph, Text, Title } = Typography;

interface Props {
  logInError?: ApolloError;
}

export const Home = ({
  logInError,
  history
}: Props & RouteComponentProps) => {
  const { loading, data } = useQuery<
    ListingsData,
    ListingsVariables
  >(LISTINGS, {
    variables: {
      filter: ListingsFilter.PRICE_HIGH_TO_LOW,
      limit: 4,
      page: 1
    },
    fetchPolicy: "cache-and-network"
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitSearch = (value: string) => {
    const trimmedSearch = value.trim();

    if (trimmedSearch) {
      history.push(`/listings/${trimmedSearch}`);
    } else {
      displayErrorMessage("Please enter a valid search!");
    }
  };

  const renderListingsSection = () => {
    if (loading) {
      return <HomeListingsSkeleton />;
    }

    if (data && data.listings.result.length) {
      return (
        <HomeListings
          title={"Premium Listings"}
          listings={data.listings.result}
        />
      );
    }

    return null;
  };

  const launchBanner =
    logInError == null &&
    sessionStorage.getItem("launchBannerSession") !==
      "close" ? (
      <Alert
        message={
          // eslint-disable-next-line
          <span>
            🎉 TinyHouse is a home-sharing app built for a
            full-stack React Masterclass.{" "}
            <strong>
              Part I of the Masterclass has just launched
            </strong>{" "}
            and you can find details{" "}
            <Link to="/about">here</Link>!
          </span>
        }
        banner
        showIcon={false}
        type="info"
        className="error-banner home-banner"
        closable
        afterClose={() =>
          sessionStorage.setItem(
            "launchBannerSession",
            "close"
          )
        }
      />
    ) : null;

  return (
    <Content className="home">
      {launchBanner}
      <HomeHero submitSearch={submitSearch} />

      <div className="home__cta-section">
        <Title
          level={2}
          className="home__cta-section-title"
        >
          Your guide for all things rental
        </Title>
        <Paragraph>
          Helping you make the best decisions in buying,
          selling, & renting your last minute locations.
        </Paragraph>
        <ButtonLink
          to="/listings/United%20States"
          size="large"
          type="primary"
          className="home__cta-section-button"
        >
          <span>Popular listings in the United States</span>
        </ButtonLink>
      </div>

      {renderListingsSection()}

      <div className="home__listings">
        <Title level={4} className="home__listings-title">
          Listings of any kind
        </Title>
        <Row gutter={12}>
          <Col xs={24} sm={12}>
            <Link to="/listings/San%20Fransisco">
              <div className="home__listings-img-cover">
                <img
                  src={sanFransiscoImage}
                  alt="San Fransisco"
                  className="home__listings-img"
                />
              </div>
            </Link>
          </Col>
          <Col xs={24} sm={12}>
            <Link to="/listings/Cancún">
              <div className="home__listings-img-cover">
                <img
                  src={cancunImage}
                  alt="Cancún"
                  className="home__listings-img"
                />
              </div>
            </Link>
          </Col>
        </Row>
      </div>

      <div className="home__cta-section">
        <Title
          level={2}
          className="home__cta-section-title"
        >
          Interested in learning how to build this app from
          scratch?
        </Title>
        <Paragraph>
          This is a demo of a{" "}
          <Text strong>
            fully functioning full stack application
          </Text>{" "}
          built with{" "}
          <a
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          ,{" "}
          <a
            href="https://www.typescriptlang.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            TypeScript
          </a>
          ,{" "}
          <a
            href="https://graphql.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            GraphQL
          </a>
          ,{" "}
          <a
            href="https://www.apollographql.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apollo
          </a>
          ,{" "}
          <a
            href="https://www.mongodb.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            MongoDB
          </a>
          , the{" "}
          <a
            href="https://ant.design"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ant Design CSS Framework
          </a>
          , and{" "}
          <a
            href="https://stripe.com/connect"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stripe Connect
          </a>
          . If you're interested in learning how to build
          this app from <Text strong>scratch</Text>, click
          below to learn more!
        </Paragraph>
        <ButtonLink
          to="/about"
          size="large"
          type="primary"
          className="home__cta-section-button"
        >
          <span>Learn more</span>
        </ButtonLink>
      </div>
    </Content>
  );
};
