import React, { useLayoutEffect } from "react";
import ReactPlayer from "react-player";
import { Icon, Layout, Typography } from "antd";

const { Paragraph, Text, Title } = Typography;
const { Content } = Layout;

export const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Content className="about">
      <div className="about-hero">
        <Title className="about-hero__title">
          Learn how to build this app
        </Title>
        <Paragraph className="about-hero__subtitle">
          with <Text strong>React</Text>,{" "}
          <Text strong>TypeScript</Text>, and{" "}
          <Text strong>GraphQL</Text>. Enroll in the
          Fullstack React Masterclass -{" "}
          <a
            href="https://newline.co/tinyhouse"
            target="_blank"
            rel="noopener noreferrer"
          >
            TinyHouse
          </a>
          .
        </Paragraph>
        <div className="about-hero__video-container">
          <div className="about-hero__video-wrapper">
            <ReactPlayer
              url="https://fullstack.wistia.com/medias/ke4dtz4r3t"
              className="about-hero__video-player"
              width="100%"
              height="100%"
            />
          </div>
        </div>

        <Text
          className="about-hero__arrow-info"
          type="secondary"
        >
          <Icon type="down-circle" /> Details about this
          demo app <Icon type="down-circle" />
        </Text>

        <div className="about-hero__demo-details">
          <Icon
            type="coffee"
            style={{
              fontSize: "30px",
              paddingBottom: "10px"
            }}
          />
          <Paragraph
            className="about-hero__demo-subtitle"
            type="secondary"
          >
            <Text strong>tinyhouse.app</Text> is a demo
            version of the TinyHouse application. All the
            listings presented in this demo app come from
            mock data with fake addresses and copyright-free
            to use images from Unsplash.
          </Paragraph>
          <Paragraph
            className="about-hero__demo-subtitle"
            type="secondary"
          >
            In this demo app, you are able to log-in with
            Google Sign-In. By signing in, the only
            information we capture is your Google account
            avatar and name shared with us through Google
            OAuth 2.0.
          </Paragraph>
          <Paragraph
            className="about-hero__demo-subtitle"
            type="secondary"
          >
            When signed in, you're able to "book" listings
            within this demo environment. A Stripe test
            environment is used for this demo app and
            listings can be booked with fake Credit Card
            information as shown here in the -{" "}
            <a
              href="https://stripe.com/docs/testing#cards"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe documentation
            </a>
            . If you want to book a listing in this demo
            app,{" "}
            <Text strong>
              please don't use real credit card information
            </Text>{" "}
            🙂.
          </Paragraph>
          <Paragraph
            className="about-hero__demo-subtitle"
            type="secondary"
          >
            We'll be re-seeding the data in this demo app on
            a week to week basis to remove any mock listings
            or bookings that might have been created, and
            remove any users that have signed in.
          </Paragraph>
          <Paragraph
            className="about-hero__demo-subtitle"
            type="secondary"
          >
            Have any questions? Send me a{" "}
            <a
              href="https://twitter.com/djirdehh"
              target="_blank"
              rel="noopener noreferrer"
            >
              DM
            </a>
            !
          </Paragraph>
        </div>
      </div>
    </Content>
  );
};
