import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Input, Row, Typography } from "antd";

// Image Assets
import torontoImage from "./assets/toronto.jpg";
import dubaiImage from "./assets/dubai.jpg";
import losAngelesImage from "./assets/los-angeles.jpg";
import londonImage from "./assets/london.jpg";

interface Props {
  submitSearch: (locationValue: string) => void;
}

const { Title } = Typography;
const { Search } = Input;

export const HomeHero = ({ submitSearch }: Props) => {
  return (
    <div className="home-hero">
      <div className="home-hero__search">
        <Title className="home-hero__title">
          Find a place you'll love to stay at
        </Title>
        <Search
          placeholder="Search 'San Fransisco'"
          size="large"
          onSearch={submitSearch}
          enterButton
          className="home-hero__search-input"
        />
      </div>
      <Row gutter={12} className="home-hero__cards">
        <Col xs={12} md={6}>
          <Link to="/listings/Toronto">
            <Card
              cover={
                <img alt="toronto" src={torontoImage} />
              }
            >
              Toronto
            </Card>
          </Link>
        </Col>
        <Col xs={12} md={6}>
          <Link to="/listings/Dubai">
            <Card
              cover={<img alt="dubai" src={dubaiImage} />}
            >
              Dubai
            </Card>
          </Link>
        </Col>
        <Col xs={0} md={6}>
          <Link to="/listings/Los%20Angeles">
            <Card
              cover={
                <img
                  alt="cape-town"
                  src={losAngelesImage}
                />
              }
            >
              Los Angeles
            </Card>
          </Link>
        </Col>
        <Col xs={0} md={6}>
          <Link to="/listings/London">
            <Card
              cover={<img alt="tokyo" src={londonImage} />}
            >
              London
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
};
